import PropTypes from "prop-types";
import Logo from "@components/logo";
import Navbar from "@components/navbar";
import { Container } from "react-bootstrap";
import HeaderAction from "@components/header/action/HeaderAction";
import { useUser } from "src/hooks/useUser";

const DesktopHeaderOne = ({
                            className = "",
                            navbarAlignment = "",
                            containerFluid = false,
                            dark = false,
                            logo = "",
                            navbar = true,
                            navData = [],
                            color,
                          }) => {
  const user = useUser();

  return (
      <div className={`tt-desktop-header ${className ? className : ""}`}>
        <div className={dark ? "tt-color-scheme-01" : "tt-header-light"}>
          <Container fluid={containerFluid}>
            <div className="tt-header-holder">
              <div className={`tt-obj-logo obj-alignment-${"left"}`}>
                <Logo src={logo} width={180} height={80} />
              </div>
                  <div
                      className={`tt-obj-menu obj-alignment-${
                          navbarAlignment ? navbarAlignment : "left"
                      }`}
                      style={{ paddingTop: 12, marginLeft: 21 }}
                  >
                    <Navbar
                        navData={navData}
                        hoverStyleClass="tt-hover-03 tt-menu-small"
                        color={color}
                    />
                  </div>
              <div className="tt-obj-options obj-move-right">
                <HeaderAction
                    className="obj-move-right"
                    search={true}
                    cart={true}
                    account={true}
                    settings={!user}
                    user={user}
                    color={color}
                />
              </div>
            </div>
          </Container>
        </div>
      </div>
  );
};

DesktopHeaderOne.propTypes = {
  className: PropTypes.string,
  navbarAlignment: PropTypes.string,
  containerFluid: PropTypes.bool,
  dark: PropTypes.bool,
  logo: PropTypes.string.isRequired,
  navbar: PropTypes.bool.isRequired,
  navData: PropTypes.array,
  color: PropTypes.string,
};

export default DesktopHeaderOne;
