'use client';

import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import { CURRENCY } from "@utils/constant";
import Tooltip from "@components/ui/tooltip";
import { CartContext } from "@global/CartContext";
import { getCartTotalPrice, getCartTotalQuantity } from "@utils/product";
import { toast } from "react-toastify";

const MiniCart = ({ className = '', isHidden = false, openHandler, closeHandler, color = 'black' }) => {
    const { shoppingCart, removeProduct } = useContext(CartContext);
    const [totalCartItems, setTotalCartItems] = useState(0);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            setTotalCartItems(getCartTotalQuantity(shoppingCart));
        }
    }, [shoppingCart, isMounted]);

    if (!isMounted) {
        return null;
    }

    return (
        <div className={`tt-parent-box ${className}`} >
            <div className={`tt-cart tt-dropdown-obj ${!isHidden ? "active" : ""}`}>
                <button className="tt-dropdown-toggle" data-id="miniCart" onClick={openHandler}>
                    <i className="icon-f-39" style={{ color }} />
                    <div className="tt-badge-cart">{totalCartItems || 0}</div>
                </button>
                <div className="tt-dropdown-menu" >
                    <div className="tt-mobile-add">
                        <h6 className="tt-title">CARRITO DE COMPRAS</h6>
                        <button className="tt-close" data-id="miniCart" onClick={closeHandler}>Cerrar</button>
                    </div>
                    <div className="tt-dropdown-inner" >
                        <div className="tt-cart-layout">
                            {shoppingCart.length <= 0 ? (
                                <span className="tt-cart-empty">
                                    <i className="icon-f-39" />
                                    <p>No hay productos en el carrito.</p>
                                </span>
                            ) : (
                                <div className="tt-cart-content" >
                                    <div className="tt-cart-list">
                                        {shoppingCart.slice(0, 3).map(product => (
                                            <div className="tt-item" key={product.cartId}>
                                                <Link href={`/product/${product.slug}`}>
                                                    <div className="tt-item-img">
                                                        {product.variations ? (
                                                            product.variations.map((variation, i) => (
                                                                variation?.color?.name === product?.color && (
                                                                    <img
                                                                        key={i}
                                                                        src={variation.color.thumb}
                                                                        alt={product.name}
                                                                    />
                                                                )
                                                            ))
                                                        ) : (
                                                            <img
                                                                src={product.thumbs[0]}
                                                                alt={product.name}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="tt-item-descriptions">
                                                        <h2 className="tt-title">{product.name}</h2>
                                                        <div className="tt-quantity">{product.quantity} X</div>
                                                        <div className="tt-price" style={{ marginLeft: 2 }}>
                                                            {CURRENCY + ' ' + product.price.toFixed(2)}
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="tt-item-close">
                                                    <button
                                                        className="tt-btn-close"
                                                        onClick={() => {
                                                            toast.error(`Se quitó "${product.name}"`, {
                                                                position: "bottom-right",
                                                                autoClose: 2000,
                                                            });
                                                            removeProduct({
                                                                cartId: product.cartId
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {shoppingCart.length > 3 && (
                                        <div className="flex-align-center">
                                            <Tooltip content="Ver Todo" className="d-flex justify-content-center">
                                                <Link href="/cart" className="btn-link-02 text-center">
                                                    . . .
                                                </Link>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <div className="tt-cart-total-row">
                                        <div className="tt-cart-total-title">SUBTOTAL:</div>
                                        <div className="tt-cart-total-price">
                                            {CURRENCY + ' ' + getCartTotalPrice(shoppingCart).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="tt-cart-btn">
                                        <div className="tt-item">
                                            <Link href="/cart" className="btn">
                                                Mi Carrito
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MiniCart;
