import PropTypes from "prop-types";
import Logo from "@components/logo";
import Navbar from "@components/navbar";
import { Container } from "react-bootstrap";
import HeaderAction from "@components/header/action/HeaderAction";
import { useUser } from "src/hooks/useUser";

const DesktopHeaderFour = ({
  navbarAlignment,
  logoAlignment,
  containerFluid,
  logo,
  navData,
}) => {

  const user = useUser()
  return (
    <div className='tt-desktop-header'>
      <Container>
        <div className='tt-header-holder'>
          <div
            className={`tt-obj-logo obj-alignment-${
              logoAlignment ? logoAlignment : "center"
            }`}
          >
            <Logo className="desktop-logo-show" src={logo} width={180} height={80} />

          </div>
          <div
            className={`tt-obj-menu obj-alignment-${
              navbarAlignment ? navbarAlignment : "left"
            }`}
            style={{paddingTop: 12, marginLeft: 21}}
          >
            <Navbar
              navData={navData}
              hoverStyleClass='tt-hover-03 tt-menu-small'
            />
          </div>
          <div className='tt-obj-options obj-move-right'>
            <HeaderAction
              className='obj-move-right'
              search={true}
              cart={true}
              account={true}
              settings={!user}
              user={user}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

DesktopHeaderFour.propTypes = {
  navbarAlignment: PropTypes.string,
  logoAlignment: PropTypes.string,
  containerFluid: PropTypes.bool,
  logo: PropTypes.string.isRequired,
  navData: PropTypes.array.isRequired,
};

export default DesktopHeaderFour;
