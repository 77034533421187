import Link from "next/link";
import PropTypes from "prop-types";

const Logo = ({ src, height, width, styles }) => {
  return (
    <Link href='/' className='tt-logo tt-logo-alignment' style={{margin: 0}}>

        <img
          src={src}
          alt='San Marino'
          style={{ height, maxHeight: height, width, ...styles }}
        />

    </Link>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Logo;
