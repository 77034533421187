import React from 'react';

const ContentWrapperOne = ({children, className = ""}) => {

    const validChildren = React.Children.toArray(children).filter((child) => child !== false &&
        child !== null && child !== undefined);

    if (validChildren.length !== React.Children.toArray(children).length) {
        console.warn("Inconsistencia en los children entre SSR y cliente");
    }

    return (
        <div
            className={className ? className : ""}
            id="tt-pageContent"
        >
            {validChildren}
        </div>
    );
};

export default ContentWrapperOne;
