import {useContext, useEffect, useRef, useState} from 'react';
import {slideUp} from "@utils/method";
import {Container} from "react-bootstrap";
import {ParametersContext} from '@global/ParametersContext';

const NotificationBarThree = ({className, containerFluid}) => {
    const promo = useContext(ParametersContext);
    const [promoText, setPromoText] = useState(null);

    const notificationBar = useRef(null);

    useEffect(() => {
        if (promo && promo.discount && promo.discount.text) {
            setPromoText(promo.discount.text);
        }
    }, [promo]);

    if (!promoText) {
        return null;
    }

    return (
        <div className={`tt-top-panel ${className ? className : ''}`} ref={notificationBar}>
            <Container fluid={containerFluid}>
                <div className="tt-row">
                    <div className="tt-description tt-promo-02">
                        {promoText}
                    </div>
                    <button
                        className="tt-btn-close" aria-label="close"
                        onClick={() => slideUp(notificationBar.current, 400)}
                    />
                </div>
            </Container>
        </div>
    );
};

export default NotificationBarThree;